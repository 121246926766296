import * as React from 'react';
import { Helmet } from 'react-helmet';

const Layout = ({ title, children }) => (
    <>
        <Helmet
            htmlAttributes={{
                lang: 'de',
            }}
        >
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
            <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap" rel="stylesheet" />
        </Helmet>
        <title>{title}</title>
        <header></header>
        <main>{children}</main>
        <footer className='mt-20 bg-gray-700 text-gray-200'>
            <div className='px-6 md:px-12 mt-12'>
                <a href='https://thierry-schmidt.ch' className='block py-6 text-center'>by Thierry Schmidt</a>
            </div>
        </footer>
    </>
);

export default Layout;
