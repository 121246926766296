import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { pushable, shadow, edge, front } from './hero.module.css';



const Hero = () => (
    <div className='px-6 lg:px-12 py-12 bg-gray-50'>
        <div className="grid md:grid-cols-2 md:items-center">
            <div>
                <span className="font-semibold bg-blue-500 text-white px-2 py-1">Personalmangel in Ihrem Team?</span>
                <h1 className="font-semibold text-5xl text-blue-500 mt-1 mb-2">Regula Kast Sozialberatung</h1>
                <p className="text-lg text-gray-800">
                    Engagierte Sozialarbeiterin in der Sozialhilfe und Sozialberatung. Ich überbrücke als Springerin Ihre personelle Notsituation.
                </p>
                <div className='mt-8'>
                    <a
                        href='#contact'
                        className="mt-8 font-serif font-bold text-blue-500 border-2 rounded-full border-blue-500 px-6 py-2 hover:bg-blue-500 hover:text-white">
                        Kontaktieren
                    </a>
                </div>
            </div>
            <div className='mt-12 md:mt-0'>
                <StaticImage
                    alt=""
                    src="../images/join.svg"
                    placeholder="tracedSVG"
                />
            </div>
        </div>
    </div>
);

export default Hero;
