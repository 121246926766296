import * as React from 'react';

const Header = () => (
    <header className='px-6 lg:px-12 bg-gray-50 border-b border-gray-200 flex justify-center md:justify-end gap-4 py-6'>
        <a href="#mainFocus" className='text-gray-800 hover:underline'>Meine Schwerpunkte</a>
        <a href="#about" className='text-gray-800 hover:underline'>Über mich</a>
        <a href="#contact" className='text-gray-800 hover:underline'>Kontakt</a>
    </header>
);

export default Header;
