import * as React from 'react';

const Contact = () => (
    <div className="mt-14 px-6 lg:px-12 py-12">
        <h2
            id="contact"
            className="font-semibold text-3xl inline-block pr-8 mb-8"
            style={{ boxShadow: '-0.75rem 1rem 0 -0.75rem #3B82F6' }}
        >
            Kontakt
        </h2>
        <div>
            <p>
                Gerne beantworte ich Ihre Anfrage für eine Offerte.
            </p>
            <div className="mt-8">
                <p>Regula Kast</p>
                <p>kastreg@mail.ch</p>
                <p>079 795 07 02</p>
            </div>
        </div>
    </div>
);

export default Contact;
