import * as React from 'react';
import { ScaleIcon, UserGroupIcon, ChatIcon, BookOpenIcon } from '@heroicons/react/outline'

const MainFocus = () => (
    <div className="px-6 lg:px-12 py-12 mt-8">
        <h2
            id="mainFocus"
            className="font-semibold text-3xl text-gray-800 inline-block pr-8 mb-8 mt-8"
            style={{ boxShadow: '-0.75rem 1rem 0 -0.75rem #3B82F6' }}
        >
            Meine Schwerpunkte
        </h2>
        <div className="mt-12 grid md:grid-cols-3 gap-x-6 gap-y-12">
            <div className="px-6 pb-8 flex flex-col items-center bg-gray-100 rounded">
                <div className="inline-block rounded p-2 bg-gradient-to-tr from-blue-500 to-blue-600 shadow -mt-6 mb-8">
                    <ScaleIcon className="w-8 h-8 text-white" />
                </div>
                <h3 className="font-semibold text-xl text-center text-gray-800">Gesetzliche Sozialarbeit in der Sozialhilfe</h3>
                <p className="text-center text-gray-800 mt-4">
                    Sozialhilferecht und Sozialversicherungen (CAS Sozialhilferecht).
                </p>
            </div>

            <div className="px-6 pb-8 flex flex-col items-center bg-gray-100 rounded">
                <div className="inline-block rounded p-2 bg-gradient-to-tr from-blue-500 to-blue-600 shadow -mt-6 mb-8">
                    <UserGroupIcon className="w-8 h-8 text-white" />
                </div>
                <h3 className="font-semibold text-xl text-center text-gray-800">Führung</h3>
                <p className="text-center text-gray-800 mt-4">
                    Fachliche und personelle Führung (CAS Führungskompetenzen).
                </p>
            </div>

            <div className="px-6 pb-8 flex flex-col items-center bg-gray-100 rounded">
                <div className="inline-block rounded p-2 bg-gradient-to-tr from-blue-500 to-blue-600 shadow -mt-6 mb-8">
                    <ChatIcon className="w-8 h-8 text-white" />
                </div>
                <h3 className="font-semibold text-xl text-center text-gray-800">Zusammenarbeit</h3>
                <p className="text-center text-gray-800 mt-4">
                    Zusammenarbeit mit Behörden und anderen Institutionen.
                </p>
            </div>
        </div>
    </div>
);

export default MainFocus;
