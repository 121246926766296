import * as React from 'react';
import { StaticImage } from "gatsby-plugin-image";

const About = () => (
    <div className="mt-20 px-6 lg:px-12 py-12 bg-gray-50">
        <h2
            id="about"
            className="font-semibold text-3xl inline-block pr-8 mb-8 mt-8"
            style={{ boxShadow: '-0.75rem 1rem 0 -0.75rem #3B82F6' }}
        >
            Über mich
        </h2>
        <div className="grid md:grid-cols-3 gap-6">
            <div className="md:col-span-2">
                <p className="text-lg max-w-lg text-justify">
                    Ich habe langjährige Berufserfahrung als Sozialarbeiterin in der Sozialhilfe und mehrjährige Leitungserfahrung. Nach meiner Pensionierung übernahm ich eine befristete Springerstelle in einem öffentlichen Sozialdienst in der Region.
                    Ich habe Erfahrung mit den Fallführungssystemen Tutoris und KLIB.
                </p>
            </div>
            <div>
                <div className="mx-auto max-w-xs">
                    <StaticImage
                        src="../images/pb.jpg"
                        alt="Regula Kast, Sozialarbeiterin"
                        aspectRatio={1}
                        className="rounded"

                    />
                </div>
            </div>
        </div>
    </div>
);

export default About;
