import * as React from "react";
import Hero from "../components/hero";
import Layout from "../components/layout";
import Header from "../components/header";
import MainFocus from "../components/mainFocus";
import About from "../components/about";
import Contact from "../components/contact";

const IndexPage = () => (
  <Layout title="Kast Soz">
    <div className="text-gray-800">
      <Header />
      <Hero />
      <MainFocus />
      <About />
      <Contact />
    </div>
  </Layout>
);

export default IndexPage;
